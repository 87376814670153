body {
  background-color: #fff;
  font-family: Poppins, Segoe UI, Roboto, ui-sans-serif;
}

.btn {
  @apply outline-none py-2 px-4 ml-4 text-white font-semibold rounded-md border-none;
}

.btn-teal {
  @apply bg-teal-500;
}

.btn-teal:hover {
  @apply bg-teal-600;
}

.login_bg_img {
  background-image: url("/images/festa_bg_blur5.jpg");
  background-position: center;
  background-size: cover;
}

.item-carousel {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.serrilha {
  background-image: url("/images/serrilha.png");
  background-repeat: repeat-x;
  background-size: 20px;
}
.serrilha-base {
  background-image: url("/images/serrilha-base.png");
  background-repeat: repeat-x;
  background-size: 20px;
}

.divider-color {
  width: 100%;
  height: 2px;
}

.item-evento-home {
  @apply bg-gray-100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-rodape {
  width: 220px;
}
.bandeira-rodape {
  width: 31px;
  height: 31px;
}
.btn-qtd {
  @apply flex items-center justify-center  w-8 h-8 font-bold cursor-pointer;
  border-radius: 20%;
}
.btn-qtd span {
  margin-top: -3px;
  margin-left: -1px;
  color: white;
}

.circulo-mesa {
  @apply rounded-full text-white font-semibold cursor-pointer text-center flex items-center justify-center text-sm;
}

.item-menu-home {
  @apply flex hover:bg-myred  hover:text-white text-gray-700 rounded p-2 md:text-sm text-lg transition-colors items-center;
}

.swiper-button-next {
  color: yellow;
}

.float-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  padding: 12px 12px 12px 12px; /* alterei o padding direito para acomodar o ícone */
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 999;
}

/* Quando o mouse passa sobre o botão flutuante */
.float-button:hover {
  background-color: #128c7e;
}

.input-style {
  @apply block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm;
}

/* .side-bar-relatorios {
    width: max-content;
} */

.resumo-relatorio-vendas {
  font-weight: bold;
  font-size: small;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.icone-senha-input {
  display: flex;
  align-items: center;
  align-content: center;
}

.div-cone-senha-input {
  background-color: #eaeaea;
  border-radius: 6px;
  padding: 2px;
  width: 32px;
  margin-left: 5px;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media (max-width: 700px) {
  .resumo-relatorio-vendas {
    grid-template-columns: 1fr;
    justify-items: stretch;
    row-gap: 5px;
  }
}

.divisoria-ingresso {
  border: 1px dashed #e7e1e1; /* Remove the default border */
}

@media print {
  .grecaptcha-badge {
    display: none !important;
  }
}

/* .grecaptcha-badge {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-items: center;
} */

.grecaptcha-badge {
  visibility: hidden;
}

.grecaptcha-badge.show {
  visibility: visible;
}

/* components/BolaSorteio.module.css */
.bola-sorteio {
  width: 80px;
  height: 80px;
  border-radius: 40%;
  background-color: #c81e1e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 5px 10px 5px;
}

.bola-sorteado {
  width: 50px;
  height: 50px;
  background-color: #e06445;
  font-size: 30px;
}

.bola-hidden {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s, transform 1s;
  display: none;
}
.bola-visible {
  opacity: 1;
  transform: translateX(0);
  display: initial;
}

.pg-sorteio {
  background: url("/bg-sorteio.jpg");
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
}

.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background: red;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  animation: confetti-fall 10s ease-out forwards;
}

@keyframes confetti-fall {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100vh, 0) rotate(360deg);
  }
}

.card-resumo {
  width: 100%;
  height: 100px;
  border-radius: 5px;

  display: grid;
  justify-items: normal;
  align-items: end;
}

.card-resumo-valor {
  font-size: 20px;
  border-radius: 0 0 5px 5px;
  width: 100%;
  justify-items: center;
  display: grid;
  align-items: center;
}

.card-resumo-descricao {
  border-radius: 0 0 5px 5px;
  width: 100%;
  height: 30px;
  justify-items: center;
  display: grid;
  align-items: center;
  font-size: 12px;
}

@media (max-width: 500px) {
  .card-resumo {
    width: 100%;
  }
}
